import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "page__header",
  style: {"margin-bottom":"0"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlatTabs = _resolveComponent("FlatTabs")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createBlock(_component_Container, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_FlatTabs, {
          links: "",
          tabs: _ctx.tabs,
          onTabClick: _ctx.tabClick
        }, null, 8, ["tabs", "onTabClick"])
      ]),
      _createVNode(_component_router_view)
    ]),
    _: 1
  }))
}