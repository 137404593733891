
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'FlatTabs',
  props: {
    links: { type: Boolean, default: false },
    currentTabIndex: { type: [String, Number], default: '0' },
    tabs: {
      type: Array as PropType<{ to?: string, name: string }[]>,
      required: true,
    },
  },
});
