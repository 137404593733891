
import { defineComponent } from 'vue';
import Container from '@/components/Container.vue';
import FlatTabs from '@/components/UI/FlatTabs/index.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'MarketsAndMalls',
  components: { FlatTabs, Container },
  setup() {
    const router = useRouter();

    const tabs = [
      {
        name: 'Рынки',
        to: '/markets-and-malls/markets',
      },
      {
        name: 'Торговые центры',
        to: '/markets-and-malls/malls',
      },
    ];

    function tabClick($event: { name: string; to: string }) {
      router.push($event.to);
    }

    return { tabs, tabClick };
  },
});
