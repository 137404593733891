import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "ui-flat-tabs" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        (_ctx.links)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              class: "ui-flat-tab",
              to: tab.to,
              "active-class": "ui-flat-tab--active"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(tab.name), 1)
              ]),
              _: 2
            }, 1032, ["to"]))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["ui-flat-tab", {'ui-flat-tab--active': _ctx.currentTabIndex === index}]),
              onClick: ($event: any) => (_ctx.$emit('tabClick', tab))
            }, _toDisplayString(tab.name), 11, _hoisted_2))
      ], 64))
    }), 256))
  ]))
}